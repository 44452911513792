
.joblisting{
    width: max-content;
    height: 48px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    margin: 0 auto;
    
}
.relative1{
    position: relative;
}
.relative1:after {
    
        position: absolute;
        content: "";
        top: 14px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: #fff transparent transparent transparent;
   
}

.input{
    position: relative;
    width: 351px;
    height: 56px;
    color: rgba(122, 136, 163, 1);
    border: 0.5px solid #7A88A3;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 0 32px;
    margin-top: 72px;
    margin-left: 30px;
    
}
.inputcategory{
    position: relative;
    width: 351px;
    height: 56px;
    color: rgba(122, 136, 163, 1);
    border: 0.5px solid #7A88A3;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 0 32px;
    margin-top: 24px;
    margin-left: 30px;
    padding-right: 15px;
    
    
}

.imgsearch{
    width: 18px;
    height: 18px;
    position: absolute;
    top: 7.5%;
    left: 85%;
    
}

.workduration{
    margin-top: 72px;
    border-top: 1px solid rgba(190, 196, 209, 1);
}

.workdurationdiv{
    
    display: flex;
    cursor: pointer;

}
.workdurationdiv p{
    width: 173px;
    height: 24px;
    margin: 32px;
    margin-left: 39px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
}

.workdurationimg{
    width: 14px;
    height: 8px;
    margin-top: 41px;
    margin-left: 107px;
}

.workdurationopen{
    margin-top: 72px;
    border-top: 1px solid rgba(190, 196, 209, 1);
    height: max-content;
    padding-bottom: 15px;
    
}
.workdurationopen .workdurationdiv{
    margin-bottom: 30px;
}
.hourdiv{
    display: flex;
    margin-top: 25px;
    width: 100%;
    height: 20px;
    margin-left: 8px;
    
    
    
}
.hourdiv input{
    margin-left: 39px;
    width: 18px;
    height: 18px;
    margin-top: 1px;
    
}

.hourdiv input[type='radio']{
    cursor: pointer;
    
    
}
.hourdiv label{
    height: 18px;
    margin-left: 20px;
    margin-top: 1px;
    display: flex;
    align-items: center;
    
}

.none{
    display: none;
}

.price{
    border-top: 1px solid rgba(190, 196, 209, 1);

}

.priceopen{
    height: max-content;
    border-top: 1px solid rgba(190, 196, 209, 1);
    padding-bottom: 15px;
}
.pricediv{
    width: 100%;
    
    display: flex;
    cursor: pointer;
    
}
.pricediv p{
    width: 173px;
    height: 24px;
    margin: 32px;
    margin-left: 39px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
}

.priceimg{
    width: 14px;
    height: 8px;
    margin-top: 41px;
    margin-left: 107px;
}

.nodata{
    text-align: center;
    margin-top: 100px;
    font-size: 56px;
    font-weight: bold;
}

.startdate{
    width: 150px;
    height: 30px;
    margin-left: 105px;
    padding: 0 15px 0 20px;
}
.aside1 {
    position: absolute;
    width: 411px;
    height: max-content;
    min-height: 1220px;;
    left: 120px;
    top: 150px;
    border: 1px solid #D9DADB;
    box-sizing: border-box;
    border-radius: 30px;
}

.aside2{
        position: absolute;
        width: 900px;
        height: max-content;
        min-height: 1220px;
        left: 550px;
        top: 150px;
        border: 1px solid #D9DADB;
        box-sizing: border-box;
        border-radius: 30px;
}
.job1{
        margin-top: 19px;
        margin-left: 35px;
        display: flex;
        width: 826px;
        height: 220px;
        border-radius: 30px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.jobimg{
    height: 80%;
    padding: 10px;
}


.jobsector{
    width: max-content;
    height: 26px;
    margin-top: 30px;
    margin-left: 24px;
    margin-bottom: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
}

.jobdescription{
    width: 440px;
    height: 85px;
    margin-left: 27px;
    margin-bottom: 5px;
    padding-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    

}

.duretion_address{
    margin-left: 27px;
    width: 440px;
    display: flex;
    margin-top: 5px;
    
}

.duration{
    width: max-content;
    margin-right: 98px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
}

.address{
    width: max-content;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;

}
.margin{
    margin-right: 10px;
}

.jobheart{
    width: 20px;
    height: 17px;
    margin-top: 37px;
    margin-left: 110px;
    margin-bottom: 40px;
}
.apple{
    width: 97px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #0DC46E;
    margin-left: 20px;
}

