* {
  font-family: 'Poppins', sans-serif;

}



.form-control {
  position: absolute;
  width: 354px;
  height: 48px;
  left: 170px;
  top: 762px;
  border: 0.5px solid #D9DADB;
  box-sizing: border-box;
  border-radius: 10px;
  color: #707280;
  padding: 5px;

}

.header {
  position: relative;
  display: flex;
  width: 1440px;
  height: 1024px;
}

.aside_left {
  position: absolute;
  max-width: 491px;
  min-height: 1024px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  
  overflow: hidden;

}

.shape1 {
  position: absolute;
  width: 554.87px;
  height: 554.87px;
  left: 220px;
  top: -300px;
  background: rgba(13, 196, 110, 0.7);
  border-radius: 160px;
  transform: rotate(-45deg);
}

.shape2 {
  position: relative;
  width: 554.87px;
  height: 554.87px;
  left: -310px;
  top: 800px;
  background: rgba(13, 196, 110, 0.7);
  border-radius: 160px;
  transform: rotate(-45deg);
}

.text {
  position: absolute;
  width: 337px;
  height: 282px;
  left: 90px;
  top: 480px;

  & h1 {
    position: absolute;
    width: 500px;
    height: 48px;
    left: -40px;
    top: -30px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: 0.05em;
    color: #0DC46E;
  }

  & p {
    position: absolute;
    width: 314px;
    height: 50px;
    left: 0px;
    top: 70px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140.4%;
    text-align: center;
    color: #777D88;
  }

  & button {
    position: absolute;
    width: 228px;
    height: 48px;
    left: 40px;
    top: 220px;
    border: 1px solid #0DC46E;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    align-items: center;
    color: #0DC46E;
    cursor: pointer;
  }
}

.aside_right {

  position: absolute;
  max-width: 949px;
  min-height: 1024px;
  left: 490px;
  top: 0px;
  background: #FFFFFF;


  h1 {
    position: absolute;
    width: 600px;
    height: 64px;
    left: 174.5px;
    top: 210px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 64px;
    color: #0DC46E;
    text-align: center;
  }

  .input1 {
    position: absolute;
    width: 354px;
    height: 48px;
    left: 295px;
    top: 592px;
    border: 0.5px solid #D9DADB;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
  }

  .input4 {
    position: absolute;
    width: 354px;
    height: 48px;
    left: 295px;
    top: 672px;
    border: 0.5px solid #D9DADB;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
  }

  .or {
    position: absolute;
    width: 55px;
    height: 18px;
    left: 447px;
    top: 510px;
    // font-family: 'Poppins', sans-serif;
    // font-style: normal;
    // font-weight: 400;
    // font-size: 18px;
    // line-height: 18px;
    // color: #747D8F;
    // text-align: center;
    display: flex;
    justify-content: center;

  }

  .button {
    position: absolute;
    width: 228px;
    height: 48px;
    left: 360.5px;
    top: 820px;
    background: #0DC46E;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    align-items: center;
    border: 1px solid #0DC46E;
    color: #FFFFFF;
    cursor: pointer;

  }

  .show_eye {
    color: #A3A3A3;
    position: absolute;
    top: 687px;
    left: 610px;
    cursor: pointer;

    img {
      width: 20px;
    }
  }
}

//.social {
//  display: flex;
//
//  & div {
//    display: flex;
//    align-items: center;
//  }
//
//  & div:first-child {
//    position: absolute;
//    width: 252px;
//    height: 48px;
//    left: 272px;
//    top: 420px;
//    border: 0.5px solid #D9DADB;
//    box-sizing: border-box;
//    border-radius: 10px;
//    cursor: pointer;
//    color: #A3A3A3;
//    padding: 5px;
//
//    & img {
//      width: 24px;
//    }
//
//    & p {
//      position: absolute;
//      width: 159px;
//      height: 16px;
//      left: 40px;
//      top: 0px;
//      font-family: 'Poppins';
//      font-style: normal;
//      font-weight: 400;
//      font-size: 16px;
//      line-height: 16px;
//      color: #747D8F;
//    }
//  }
//
//  & div:last-child {
//    position: absolute;
//    width: 252px;
//    height: 48px;
//    right: 272px;
//    top: 420px;
//    border: 0.5px solid #D9DADB;
//    box-sizing: border-box;
//    border-radius: 10px;
//    cursor: pointer;
//    color: #A3A3A3;
//    padding: 5px;
//
//    & img {
//      width: 24px;
//    }
//
//    & p {
//      position: absolute;
//      width: 180px;
//      height: 16px;
//      left: 40px;
//      top: 0px;
//      font-family: 'Poppins';
//      font-style: normal;
//      font-weight: 400;
//      font-size: 16px;
//      line-height: 16px;
//      color: #747D8F;
//    }
//  }
//}

.forgot_pass {
  position: absolute;
  width: 159px;
  height: 18px;
  left: 490px;
  top: 720px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #A3A3A3;
  cursor: pointer;
}


.login_google {
  position: absolute !important;
  box-sizing: border-box !important;
  width: 252px;
  height: 48px;
  left: 200px;
  top: 360px;
  border: 0.5px solid #D9DADB !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  transition: none !important;
  padding: 0 !important;
  color: #747D8F !important;
  outline: 0 !important;
}

.kep-login-facebook {
  position: absolute;
  box-sizing: border-box !important;
  width: 252px;
  height: 48px;
  left: 500px;
  top: 360px;
  border: 0.5px solid #D9DADB !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  background-color: #FFF !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #747D8F !important;

}

// .modal,
// .overlay {
//     display: block;
//     width: 100vw;
//     height: 100vh;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     position: fixed;
// }

// .modal {
//     z-index: 100;
// }

// .close {
//     background: none;
//     outline: none;
//     border: none;
//     cursor: pointer;
//     position: absolute;
//     right: 15px;
//     top: 10px;
//     font-size: 20px;
// }

// .modal-content-sign {
//     position: absolute;
//     padding: 40px 0;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 500px;
//     background: #FFFFFF;
//     box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
//     border-radius: 20px;
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     align-items: center;
// }

// .textEmail {
//     color: green;

// }

// .okBtn {
//     margin-top: 10px;
//     background-color: green;
//     border: none;
//     color: white;
//     width: 100px;
//     height: 30px;
//     border-radius: 20px;
//     outline: none;
//     border: none;

// }

// .forgot_btn {
//     margin-top: 10px;
//     background-color: white;
//     border: none;
//     color: green;
// }

// .signin {
//     color: white;
//     cursor: pointer;
// }

// .submit_and_forgot {
//     text-align: right;
// }

// .forgot_header {
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 48px;
//     line-height: 48px;
//     color: #202029;
//     padding-bottom: 20px;
// }

// .forgot_text {
//     text-align: center;
// }

// input[type=number]::-webkit-inner-spin-button {
//     -webkit-appearance: none;
// }

// .forgot_text_text {
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 16px;
//     color: green;
// }

// .form_style {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     gap: 20px
// }

// .form_style>div {
//     width: 354px;
// }

// .form_style>div span {
//     color: red;
//     padding-top: 10px;
// }

// .form_style div label {
//     font-family: 'Montserrat arm';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 16px;
//     color: #3D424E;
// }

// .form_style div input {
//     font-family: 'Montserrat arm';
//     font-style: normal;
//     width: 354px;
//     height: 48px;
//     padding: 10px 35px 10px 20px;
//     overflow: hidden;
//     border-radius: 30px;
//     border: 1px solid #A3B0BC;
//     outline: none;
//     font-size: 20px;
//     color: #3D424E;
//     margin-top: 8px;
// }

// #submit-btn {
//     width: 356px;
//     height: 48px;
//     background: green;
//     box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
//     border-radius: 30px;
//     border: none;
//     outline: none;
//     font-size: 25px;
//     color: #FFFFFF;
//     cursor: pointer;
//     padding: 0;
// }

// #send-btn {
//     width: 356px;
//     height: 48px;
//     background: green;
//     box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
//     border-radius: 30px;
//     border: none;
//     outline: none;
//     color: #FFFFFF;
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 18px;
//     line-height: 18px;
//     padding: 0;
// }

// .verify_email_content {
//     padding: 10px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     gap: 20px
// }

// .verify_email_content>div {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 30px
// }

// .verify_text {
//     font-size: 18px;
//     color: #3D424E
// }

// .verify_email_content>div>button {
//     background-color: green;
//     width: 190px;
//     height: 35px;
//     box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
//     border-radius: 30px;
//     border: none;
//     outline: none;
//     color: #FFFFFF;
//     padding: 10px 20px;
//     font-family: 'Poppins';
//     font-style: normal;
//     font-size: 18px;
//     cursor: pointer;
//     padding:  0;
//     line-height: 18px;
// }

// .password_div {
//     position: relative;
// }

// .password_eye {
//     position: absolute;
//     top: 43px;
//     right: 15px;
//     user-select: none;
// }

// /* .email_input::placeholder {
//     font-size: 10px;
// } */

// .btn-acc {
//     border: none;
//     outline: none;
//     font-size: 14px;
//     background: none;
//     text-decoration: underline;
//     cursor: pointer;
// }

// .buttonOk {
//     color: green;
// }

// .forgotBtn {
//     color: green;
//     border: none;
//     background-color: white;
//     cursor: pointer;
//     margin-left: 245px;
// }

// .link_sent {
//     color: red;
//     font-size: 18px;
// }
// @media (min-width: 300px) and (max-width: 600px) {
//     .btn-modal {
//         font-size: 24px;
//         text-decoration: none;
//         color: black
//     }
// }