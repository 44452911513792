.h2{
    width: 227px;
    height: 32px;
    margin-top: 64px;
    margin-left: 87px;
    margin-bottom: 60px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
}

.ratingp{
    width: 172px;
    height: 24px;
    margin-left: 87px;
    margin-bottom: 48px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;

}

.flex{
    display: flex;
}

.star{
    height: 33px;
    margin-left: 284px;
    margin-top: 18px;
    display: flex;
}

.star img{
    width: 31px;
    height: 32px;
    margin-left: 7px;
}

.star p{
    margin-top:  0px;
    margin-left: 92px;
    width: 76px;
    height: 32px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
}

.star p span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    color: rgba(244, 182, 0, 1);
}


.hr{
    width: 83%;
}

.review25{
    width: 121px;
    height: 24px;
    margin-left: 87px;
    margin-top: 44px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 58px;
}
.reviewdiv{
    display: flex;
    flex-wrap: wrap;
    
}
.reviewdivlist{
    flex: 25%;
    width: 326px;
    height: 218px;
    background: rgba(229, 229, 229, 0.09);
    border: 0.5px solid #D9DADB;
    border-radius: 10px;
    margin-bottom: 48px;
    margin-left: 111px;
    margin-right: 79px;
}

.reviewdivlisttop{
    display: flex;
    
}

.listuser{
    width: 62px;
    height: 58px;
    margin-top: 31px;
    margin-left: -24px;
    background-color:rgba(165, 218, 239, 1);
    border-radius: 12px;

}
.listp{
    width: 74px;
    height: 16px;
    margin-left: 8px;
    margin-top: 32px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

.liststar{
    margin-left: 50px;
    margin-top: 30px;
}

.liststar img{
    width: 15px;
    height: 15px;
    margin-left: 7px;
}

.listdate{
    width: 59px;
    height: 10px;
    margin-left: 46px;
    margin-top: -25px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
}

.listhr{
    width: 170px;
    margin-left: 108px;
    margin-top: -15px;
}
.listh3{
    width: 96px;
    height: 16px;
    margin-left: 46px;
    margin-top: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

.listbottomp{
    width: 248px;
    height: 72px;
    margin-top: 8px;
    margin-left: 46px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}