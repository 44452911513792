
.fullPage{
  width: 302px;
  height: 1018px;
  margin-top: 64px;
  margin-left: 79px;
  border-radius: 30px;
  background: #FFFFFF;
  box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
}
.active{
  background-color:rgba(52, 217, 118, 1)
}

.imgWork1 {
  
  margin-top: 59px;
  margin-left: 59px;
  margin-bottom: 32px;
  width: 184px;
  height: 184px;
  border-radius: 100%;
  background: rgba(196, 196, 196, 0.57);
}

.foto{
  width: 42px;
  height: 42px;
  margin-left: 126px;
  background: #FFFFFF;
  box-shadow: 0px -1px 50px 1px rgba(0, 67, 101, 0.08);
  border-radius: 50%;
  cursor: pointer;
}
.foto img{
  width: 19px;
  height: 16px;
  margin-top: 12px;
  margin-left: 13px;

}

.foto:hover img{
  width: 25px;
  height: 25px;
  margin-top: 7px;
  margin-left: 10px;
}

.usernamejob {
  width: 160px;
  height: 24px;
  margin-left: 75px;
  margin-bottom: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #1F2833;
}
.div2 {
  width: 136px;
  height: 18px;
  margin-left: 84px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 104px;
}
.div3 {
  margin-bottom: 1px;
  margin-top: 2px;
  width: 302px;
  height: 56px;
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  text-decoration: none;
}

.div3 img{
  width: 20px;
  height: 20px;
  margin-left: 43px;
  margin-right: 35px;
  margin-top: 18px;
}

.div3:hover{
  background-color:rgba(52, 217, 118, 1) ;
}
.div3:hover h2{
  color: white;
  font-size: 24px;
}
.div3:hover img{
  filter: invert(1);
}
.div3 h2{
  width: max-content;
  margin-top: 19px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #0D0D0D;
}

