.forgoth1{
    width: 541px;
    height: 64px;
    margin-left: 490px;
    margin-top: 100px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 64px;
    color: #0DC46E;
}

.forgotp{
    width: 612px;
    height: 60px;
    margin-top: 56px;
    margin-left: 454px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #777D88;
}

.forgotlalel{
    width: 135px;
    height: 18px;
    margin-left: 520px;
    margin-top: 104px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
}

.forgotinput{
        width: 450px;
        height: 48px;
        margin-left: 520px;
        margin-top: 16px;
        background: rgba(229, 229, 229, 0.19);
    border: 0.5px solid #D9DADB;
    border-radius: 10px;
}

.forgotinput::placeholder{
    width: 243px;
    height: 24px;
    padding-left: 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    align-items: center;
    color: #A6A9AF;
}

.forgotbutton{
    width: 228px;
    height: 48px;
    margin-left: 640px;
    margin-top: 104px;
    background: #0DC46E;
    border-radius: 10px;
    color: white;
    box-sizing: border-box;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    

}