.myprofile{
    width: max-content;
    height: 32px;
    margin-top: 64px;
    margin-left: 87px;
    margin-bottom: 80px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
}


.myprofilep{
    height: 50px;
    margin-left:87px;
    font-size: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 1px solid rgba(217, 218, 219, 1);
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 87px;
    padding-left:300px;
    background-color: rgba(229, 229, 229, 0.19);
    
    
}
.margin{
    width: 120px;
    margin-right: 50px;
    color: rgba(52, 217, 118, 1);
    
}



