.profContainer{
    display: flex;
    justify-content: center;
    
}
.postjob1{
display: flex;
justify-content: center;
gap: 100px;
}
.outletdiv{
    
    width: 1000px;
    height: 1018px;
    margin-top: 64px;
    margin-left: 24px;
    border-radius: 30px;
    background: #FFFFFF;
    box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
    border-radius: 30px;
}