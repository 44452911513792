.position{
    position: absolute;
    top: 380px;
    left: 650px;
    width: max-content;
    height: 48px;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px; 
    color: #1F2833;
    
    
}
.mywallet{
  width: 158px;
  height: 32px;
  margin-top: 64px;
  margin-left: 87px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
}
  
.flex{
  display: flex;
}

.nodata{
  text-align: center;
  margin-top: 100px;
  font-size: 56px;
  font-weight: bold;
}

.invoicing{
  width:243px;
  height:24px;
  margin-left: 87px;
  margin-top: 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;

}

.blockw{
  width: 800px;
  height: 24px;
  margin-top: 48px;
  margin-left: 92px;
  margin-right: 91px;
  margin-bottom: 64px;
  
}

.jobname{
  width: 124px;
  height: 24px;
  margin-right: 233px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: rgba(112, 114, 128, 1);
}

.sentdata{
  width: 127px;
  height: 24px;
  margin-right: 233px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: rgba(112, 114, 128, 1);

}

.billed{
  width: 64px;
  height: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: rgba(112, 114, 128, 1);
  
}

.blockwb{
  height: 64px;
  margin-left: 70px;
  margin-right: 50px;
  border-top: 1px solid black;
  
}

.uldesign{
  width: 75px;
  height: 16px;
  margin-top:20px;
  margin-left: 51px;
  margin-right: 255px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.sentdatatb{
  width: 115px;
  height: 16px;
  margin-top:20px;
  margin-right: 224px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.billedtb{
  width: 115px;
  height: 16px;
  margin-top:20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.totalbilled{
  margin-left: 70px;
  margin-top: 80px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  justify-content: space-between;
  margin-right: 69px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}


.totalbilled .number{
  color: #0DC46E;
}


.none{
  display: none;
}