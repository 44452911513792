.h2About{
    display: flex;
    position: absolute;
    top:150px;
    left:20px;  
    color: green;
}
.coming{
    position: absolute;
    top:200px;
    left:20px;
}