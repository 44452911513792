* {
  font-family: "Poppins", sans-serif;
  
}

.signup {
  height: 500px;
  width: 1440px;
  
  
}



.form-control_reg {
  position: absolute;
  width: 354px;
  height: 48px;
  left: 108px;
  top: 677px;
  border: 0.5px solid #d9dadb;
  box-sizing: border-box;
  border-radius: 10px;
  color: #000;
  padding: 5px;
}

.header_SignUp1 {
  position: relative;
  display: flex;
}

.aside_left_SignUp {
  position: absolute;
  width: 491px;
  height: 1024px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border-right: 1px solid #56d69a;
  overflow: hidden;
  border: none;
}

.shape1_reg {
  position: absolute;
  width: 554.87px;
  height: 554.87px;
  left: 220px;
  top: -300px;
  background: rgba(13, 196, 110, 0.7);
  border-radius: 160px;
  transform: rotate(-45deg);
}

.shape2_reg {
  position: relative;
  width: 554.87px;
  height: 554.87px;
  left: -310px;
  top: 800px;
  background: rgba(13, 196, 110, 0.7);
  border-radius: 160px;
  transform: rotate(-45deg);
}

.text_SignUp {
  position: absolute;
  width: 337px;
  height: 282px;
  left: 65.5px;
  top: 457px;

  & h1 {
    position: absolute;
    width: 360px;
    height: 48px;
    left: 0px;
    top: -30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: 0.05em;
    color: #0dc46e;
    text-align: center;
  }

  & p {
    position: absolute;
    width: 314px;
    height: 50px;
    left: 23px;
    top: 70px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140.4%;
    text-align: center;
    color: #777d88;
  }

  & button {
    position: absolute;
    width: 228px;
    height: 48px;
    left: 65.5px;
    top: 220px;
    border: 1px solid #0dc46e;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    align-items: center;
    color: #0dc46e;
    cursor: pointer;
  }
}

.aside_right_SignUp {
  position: absolute;
  width: 949px;
  height: 1024px;
  left: 492px;
  top: 0px;
  background: #ffffff;

  .user {
    position: relative;
    width: 17px;
    height: 17px;
    left: 487px;
    top: 290px;
    border: 0.5px solid #0dc46e;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 1;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;

    div {
      width: 11px;
      height: 11px;
      left: 2px;
      top: 2px;
      border-radius: 2px;
    }
  }

  .provider {
    position: absolute;
    width: 17px;
    height: 17px;
    left: 615px;
    top: 454px;
    border: 0.5px solid #0dc46e;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 1;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;

    div {
      width: 11px;
      height: 11px;
      left: 2px;
      top: 2px;
      border-radius: 2px;
    }
  }

  .user_name {
    position: absolute;
    top: 435px;
    left: 532px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: #707280;
  }

  .provider_name {
    position: absolute;
    top: 436px;
    left: 660px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;

    color: #707280;
  }

  & h1 {
    position: absolute;
    width: 513px;
    height: 64px;
    left: 220px;
    top: 130px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 64px;
    color: #0dc46e;
    text-align: center;
  }

  .input1_reg {
    position: absolute;
    width: 354px;
    height: 48px;
    left: 108px;
    top: 437px;
    border: 0.5px solid #d9dadb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
    color: #000;
  }

  .input2_reg {
    position: absolute;
    width: 354px;
    height: 48px;
    left: 108px;
    top: 517px;
    background: #ffffff;
    border: 0.5px solid #d9dadb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
    color: #000;
  }

  .input3_reg {
    position: absolute;
    width: 354px;
    height: 48px;
    left: 108px;
    top: 597px;
    border: 0.5px solid #d9dadb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
    color: #000;
  }

  .input4_reg {
    position: absolute;
    width: 354px;
    height: 48px;
    right: 108px;
    top: 517px;
    border: 0.5px solid #d9dadb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
    color: #000;

    //&:focus {
    //  outline-color: red
    //
    //}
  }

  .input5_reg {
    position: absolute;
    width: 354px;
    height: 48px;
    right: 108px;
    top: 597px;
    border: 0.5px solid #d9dadb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
    color: #000;
  }

  .input6_reg {
    position: absolute;
    width: 354px;
    height: 48px;
    right: 108px;
    top: 677px;
    border: 0.5px solid #d9dadb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
    color: #000;
  }

  .special-label {
    /*Phone Number Input*/
    color: green;
    visibility: hidden;
  }

  /*  & form {
    & div {

      div:nth-child(1) {

        label {
          position: absolute;
          width: 40px;
          height: 18px;
          left: 585px;
          top: 537px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #707280;
          cursor: pointer;

        }
      }

      div:nth-child(2) {

        label {
          position: absolute;
          width: 144px;
          height: 18px;
          left: 730px;
          top: 537px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #707280;
          cursor: pointer;

        }
      }

    }*/

  .button_reg {
    position: absolute;
    width: 228px;
    height: 48px;
    left: 366px;
    top: 810px;
    background: #0dc46e;
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    align-items: center;
    border: 1px solid #0dc46e;
    color: #ffffff;
    cursor: pointer;
  }

  .show_eye_reg {
    span:first-child {
      color: #a3a3a3;
      position: absolute;
      top: 532px;
      right: 115px;
      cursor: pointer;

      img {
        width: 20px;
      }
    }

    span:last-child {
      color: #a3a3a3;
      position: absolute;
      top: 612px;
      right: 115px;
      cursor: pointer;

      img {
        width: 20px;
      }
    }
  }
}

.must_be {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 570px;
  left: 488px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  color: #ea4335;

  p {
    margin-right: 8px;
  }

  & p:first-child {
    color: #a3a3a3;
  }
}

/*.social_bar {
  display: flex;

  & div {
    display: flex;
    align-items: center;
  }

  & div:first-child {
    position: absolute;
    width: 252px;
    height: 48px;
    left: 272px;
    top: 352px;
    border: 0.5px solid #D9DADB;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    color: #A3A3A3;
    padding: 5px;

    & img {
      width: 24px;
    }

    & p {
      position: absolute;
      width: 159px;
      height: 16px;
      left: 40px;
      top: 0px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      !* identical to box height, or 100% *!
      color: #747D8F;
    }
  }

  & div:last-child {
    position: absolute;
    width: 252px;
    height: 48px;
    right: 272px;
    top: 352px;
    border: 0.5px solid #D9DADB;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    color: #A3A3A3;
    padding: 5px;

    & img {
      width: 24px;
    }

    & p {
      position: absolute;
      width: 180px;
      height: 16px;
      left: 40px;
      top: 0px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      !* identical to box height, or 100% *!
      color: #747D8F;
    }
  }

}*/

.second_form {
  position: absolute;
  width: 949px;
  height: 1024px;
  left: 0px;
  top: 0px;
  background: rgba(255, 255, 255, 0.73);
  z-index: 10;

  .input6 {
    border: 0.5px solid #d9dadb;
  }
}

.input7_reg {
  position: absolute;
  top: 980px;
  left: -491px;
  background-color: transparent;
  border: none;
  color: transparent;
  border-inline: none;
}

.input7_reg:focus {
  visibility: hidden;
}
