header {
    position: relative;
    width: 1488px;
    height: max-content;
    margin: 0 auto;

    
}

.container {
      max-width: 1488px;
      min-height: max-content;
      margin: 0 auto;

  }


.red{
  height: max-content;
}









