.position{
    position: absolute;
    top: 380px;
    left: 650px;
    width: max-content;
    height: 48px;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px; 
    color: #1F2833;
    
    
}
.postajob{
  width: max-content;
  height: 32px;
  margin-top: 64px;
  margin-left: 87px;
  margin-bottom: 60px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
}
  
.flex{
  display: flex;
}


.filter{
  margin-left: calc(100% - 130px);
  width: max-content;
  margin-right: 55px;
  display: flex;
  align-items: center;
  margin-top: 62px;
}

.filter img{
  width: 20px;
  height: 20px;
  margin-right: 10px;
  top: 2px;
}

.filter span{
  width: 44px;
  height: 18px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  margin-right: 55px;
}

.block{
  width: 922px;
  height: 48px;
  margin-top: 34px;
  margin-left: 16px;
  margin-bottom: 32px;
  margin-right: 16px;
  background: rgba(229, 229, 229, 0.19);
  border: 0.5px solid #D9DADB;
  border-radius: 10px;
}

.id{
  width: 17px;
  height: 18px;
  margin-top: 14px;
  margin-left: 70px;
  margin-right: 93px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  
}
.idt{
  width: 50px;
  height: 14px;
  margin-top: 25px;
  margin-left: 64px;
  margin-right: 64px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  
}

.name{
  width: 55px;
  height: 18px;
  margin-top: 14px;
  margin-right: 127px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  
}
.namet{
  width: 130px;
  height: 14px;
  margin-top: 25px;
  margin-right: 60px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  
}
.orederterm{
  width: 103px;
  height: 18px;
  margin-top: 14px;
  margin-right: 45px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  
}

.oredertermt{
  width: 84px;
  height: 14px;
  margin-top: 25px;
  margin-right: 64px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  
}

.type{
  width: 44px;
  height: 18px;
  margin-top: 14px;
  margin-right: 101px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  
}
.typet{
  width: 81px;
  height: 14px;
  margin-top: 25px;
  margin-right: 64px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  
}

.status{
  width: 58px;
  height: 18px;
  margin-top: 14px;
  margin-right: 84px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  
}
.statust{
  width: 90px;
  height: 14px;
  margin-top: 25px;
  margin-right: 51px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

}

.price{
  width: 45px;
  height: 18px;
  margin-top: 14px;
  margin-right: 70px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  
}
.pricet{
  width: 45px;
  height: 14px;
  margin-top: 25px;
  margin-right: 31px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  
}
.blocki{
  width: 922px;
  height: 64px;
  top: 700px;
  margin-left: 15px;
  margin-bottom: 32px;
  margin-right: 16px;
  background: rgba(229, 229, 229, 0.19);
  border: 0.5px solid #D9DADB;
  border-radius: 10px;
}
.blockinput{
  position: absolute;
  width: 922px;
  height: 48px;
  top: 700px;
  left: 420px;
  margin-bottom: 32px;
  margin-right: 16px;
  background: rgba(229, 229, 229, 0.19);
  border: 0.5px solid #D9DADB;
  border-radius: 10px;
}
.idi{
  width: 60px;
  height: 18px;
  margin-top: 14px;
  margin-left: 64px;
  margin-right: 45px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  
}

.namei{
  width: 140px;
  height: 18px;
  margin-top: 14px;
  margin-right: 50px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  
}

.oredertermi{
  width: 105px;
  height: 18px;
  margin-top: 14px;
  margin-right: 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  
}

.typei{
  width: 100px;
  height: 18px;
  margin-top: 14px;
  margin-right: 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  
}

.statusi{
  width: 100px;
  height: 18px;
  margin-top: 14px;
  margin-right: 30px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  
}

.pricei{
  width: 100px;
  height: 18px;
  margin-top: 14px;
  margin-right: 70px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  
}

.btn{

  width: 50px;
  height: 40px;
  margin-top: 5px;
  margin-left: -35px;
}


.nodata{
  text-align: center;
  margin-top: 100px;
  font-size: 56px;
  font-weight: bold;
}

.paginationBttns{
  width: 98%;
  height: 40px;
  list-style: none;
  display:flex;
  justify-content: right;
  margin-top: 20px;
  
}

.paginationBttns a{
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid rgba(229, 229, 229, 0.19);

}

.paginationBttns a:hover{
  background-color: slategray; 
  color: white;
  cursor: pointer;
}

.paginationActive a{
  background-color: slategray; 
  color: white;
}