nav {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 290px;
  padding-bottom: 50px;
}
.category{
  cursor: pointer;
}
ul {
  margin: 0;
  padding: 0;
}
.motaka{
  color: #0DC46E;
}

nav > ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: 30px;
}

nav > ul > li > a {
  color: black;
  text-decoration: none;
}

.dropdown-content > a {
  color: black;
  text-decoration: none;
  font-size: 18px;
  box-shadow: 0px 8px 16px 0px rgba(109, 108, 108, 0.2);
  border-radius: 5px;
  padding: 10px;
}

.search {
  border-radius: 30px;
  padding-left: 40px;
  width: 258px;
  height: 40px;
  border: none;
  outline: none;
}

.signup {
    background-color: #0DC46E;
  height: 48px;
  width: 179px;
  left: 1181px;
  top: 80px;
  border-radius: 30px;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  width: 170px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 5px;
  flex-direction: column;
  list-style: none;
  gap: 10px;
}

.dropdown:hover .dropdown-content {
  display: flex;
}

.search_input {
  position: relative;
}

.search_input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
}

.search_input > img {
  pointer-events: none;
  position: absolute;
  left: 10px;
  top: 9px;
  width: 26px;
  height: 26px;
}

.menu {
  width: 30px;
  height: 30px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
}

.menu > span {
  width: 16px;
  height: 2px;
  background-color: black;
}

.responsive_menu {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 100;
  background: linear-gradient(
    179.25deg,
    #edcfff -35.86%,
    rgba(226, 214, 255) 26.04%,
    rgba(249, 216, 255) 55.24%,
    #ffffff 94.1%
  );
  top: 60px;
  left: 0;
  padding-top: 30px;
}

.resp_ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  list-style: none;
}

.resp_category {
  font-size: 24px;
}

.resp_ul > li > a {
  font-size: 24px;
  text-decoration: none;
  color: black;
}

.resp_ul > li > :hover {
  color: #9832fe;
}

.closeMenu {
  font-size: 30px;
  font-family: "Arial";
  cursor: pointer;
}

.resp_menu {
  display: none;
}

.category_submenu {
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  list-style: none;
}

.category_submenu > li > a {
  font-size: 16px;
  text-decoration: none;
  color: black;
}

.category_submenu > li > a:hover {
  color: #9832fe;
}

.dropdown_resp:hover .category_submenu {
  display: flex;
}

.profile_pic_menu {
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.login_user_menu {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.settings {
  text-decoration: none;
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  line-height: 16px;
}
.log_out {
  padding: 10px 0;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #202029;
  background: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.over {
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  /* z-index: 101; */
}
.privace_poilcy_txt {
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #202029;
  display: flex;
  align-items: center;
}

.modal_menu_for_login {
  background-color: #ffffff;
  box-shadow: 0px 5px 50px rgba(40, 44, 46, 0.08);
  width: 194px;
  height: 200px;
  position: absolute;
  top: 55px;
  right: -30px;
  border-radius: 16px;
  background-color: white;
  padding: 0 10px;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  color: #202029;
  z-index: 100;
}
.modal_menu_for_login > a {
  padding: 10px 0;
}

.login_user_menu > button {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 0;
  outline: 0;
  cursor: pointer;
}

@media (min-width: 300px) and (max-width: 600px) {
  .menu-list,
  .upload-btn,
  .login-btn {
    display: none;
  }

  nav > ul {
    gap: 0px;
  }

  nav {
    gap: 25px;
  }

  .search {
    width: 200px;
    height: 32px;
    padding-left: 40px;
  }

  .search_input > img {
    left: 15px;
    top: 5px;
  }

  .book-icon {
    width: 24px;
  }

  .right-list {
    gap: 0px;
  }

  .menu {
    display: flex;
  }

  .resp_menu {
    display: block;
  }
}

.profile_users_img{
  width:45px;
  height:45px;
  border-radius: 50%;
  background-color: #0DC46E;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

}

.profile_users_img_logout{
  width:45px;
  height:45px;
  border-radius: 50%;
  background-color: #0DC46E;
  border: none;
  cursor: pointer;
  


}

.logout_img{
  width: 30px;
  height: 30px;
  filter: invert(1);
  margin-left: 10px;
  margin-top: 7px;
}
.logout_img:hover{
  width: 35px;
  height: 33px;
  margin-left: 9px;

}

.login-btn{
  display: flex;
}


.user_img:hover{
  width: 25px;
  height: 25px;
}